export const INJURY_ACTION_TYPES = {
  FETCH_INJURIES_SUCCESS: 'FETCH_INJURIES_SUCCESS',
  FETCH_INJURIES_ERROR: 'FETCH_INJURIES_ERROR',
  FETCH_TEAM_INJURIES_SUCCESS: 'FETCH_TEAM_INJURIES_SUCCESS',
  FETCH_TEAM_INJURIES_ERROR: 'FETCH_TEAM_INJURIES_ERROR',
  FETCH_INJURY_OBSERVATIONS_SUCCESS: 'FETCH_INJURY_OBSERVATIONS_SUCCESS',
  FETCH_INJURY_OBSERVATIONS_ERROR: 'FETCH_INJURY_OBSERVATIONS_ERROR',
  ADD_INJURY_OBSERVATION_SUCCESS: 'ADD_INJURY_OBSERVATION_SUCCESS',
  ADD_INJURY_OBSERVATION_ERROR: 'ADD_INJURY_OBSERVATION_ERROR',
  INJURY_ERROR: 'INJURY_ERROR',
  ADD_INJURY_SUCCESS: 'ADD_INJURY_SUCCESS',
  ADD_INJURY_ERROR: 'ADD_INJURY_ERROR',
  UPDATE_INJURY_SUCCESS: 'UPDATE_INJURY_SUCCESS',
  UPDATE_INJURY_ERROR: 'UPDATE_INJURY_ERROR',
  DELETE_INJURY_SUCCESS: 'DELETE_INJURY_SUCCESS',
  DELETE_INJURY_ERROR: 'DELETE_INJURY_ERROR',
};