import { POSITION_ACTION_TYPES } from './position.types';

const initialState = {
  positions: [],
};

export default function positionReducer(state = initialState, action) {
  switch (action.type) {  
    case POSITION_ACTION_TYPES.FETCH_TEAM_POSITION_SUCCESS:
      return {
        ...state,
        positions: action.payload,
      };
    default:
      return state;
  }
}