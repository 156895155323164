import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  ReferenceLine,
} from 'recharts';

/**
 * @function CustomLineBarChart
 * @param {array} -- data es un arreglo de objetos con 5 valores: clave es para el eje x y valor para el eje y
 * @returns {ComposedChart}
 */

const RPEWeekCustomComposedChart = ({ data }) => {
    const COLORS = ['#3E4095', '#5D60A7'];

    const CustomizedLabel =(props) =>{
      const { x, y, value } = props;
      return (<text x={x-5} y={y} >{value !== null ? (value < 0 ? `<${value*-1}%` : `>${value}%`) : '' }</text>
      )
    }
    
    const CustomizedAxisTick = props => {
      // eslint-disable-next-line no-unused-vars
      const { x, y, stroke, payload } = props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={7}
            textAnchor="end"
            fill="#666"
            transform="rotate(-90)"
          >
            {payload.value}
          </text>
        </g>
      );
    };

    return (
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <ReferenceLine
            y={0}
            yAxisId="left"
            stroke="black"
            strokeDasharray="3 3"
          />
          <Legend verticalAlign="top" height={30} />
          <Tooltip />

          <XAxis
            dataKey="date"
            tick={<CustomizedAxisTick />}
            height={70}
            interval={0}
            padding={{ left: 20, right: 20 }}
          />
          <Bar
            name="UA"
            yAxisId="left"
            dataKey="value"
            barSize={20}
            fill="#413ea0"
            isAnimationActive={false}
          >
            {data.map((item, index) => {
              const color = index % 2 === 0 ? COLORS[0] : COLORS[1];
              return (<Cell key={item.value} fill={color} />)
            })}
            <LabelList dataKey="diff" position="top" content={<CustomizedLabel />}  />
          </Bar> )
        
          <YAxis
            dataKey="value"
            yAxisId="left"
            orientation="left"
          />
      </ComposedChart>
      </ResponsiveContainer>
    )
}; 


RPEWeekCustomComposedChart.defaultProps = {
  data: [],
};

RPEWeekCustomComposedChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      value: PropTypes.number,
      diff: PropTypes.number,
    })
  ),
};
export default RPEWeekCustomComposedChart;