import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import { translations } from '../constants/table-localization';
import { teamsSelector } from '../store/selectors';
import * as teamActions from '../store/team/team.actions';
import * as categoryActions from '../store/category/category.actions';
import * as sportActions from '../store/sport/sport.actions';
import { categoriesSelector } from '../store/selectors';
import moment from 'moment';
import { sportsSelector } from '../store/selectors';
import { setSuccessMsg } from '../store/app/app.actions';
import { defaultColumnsConstructor } from '../constants/table/teamListColumns';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
}));

const TeamList = ({
  fetchTeams,
  teams,
  fetchCategories,
  categories,
  fetchSports,
  sports,
  addTeam,
  updateTeam,
  deleteTeam,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);

  const tableOptions = {
    search: false,
    sorting: true,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    paging: false,
    addRowPosition: 'first',
  };

  useEffect(() => {
    fetchTeams();
    fetchCategories();
    fetchSports(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <MaterialTable
        title={
          <Typography variant="h5" color="textPrimary" align="center">
            Listado de equipos
          </Typography>
        }
        onChangeRowsPerPage={value => {
          setPageSize(value);
        }}
        columns={defaultColumnsConstructor(categories, sports)}
        options={tableOptions}
        localization={translations}
        data={teams}
        actions={[
          {
            onClick: (event, rowData) => {},
            icon: 'list_alt',
            tooltip: 'Ver detalle',
          },
        ]}
        editable={{
          isEditable: rowData => true,
          isDeletable: rowData => true,
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const {
                name,
                description,
                category,
                sport,
                initialDate,
                endDate,
              } = newData;
              if (
                !name ||
                !description ||
                !category ||
                !sport ||
                !initialDate ||
                !endDate
              ) {
                reject();
              }
              addTeam({
                name,
                description,
                category: category.id,
                sport: sport.id,
                initialDate: moment(initialDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
              }).then(res => {
                setSuccessMsg('Equipo agregado correctamente')
                resolve();
              })
              .finally(()=> fetchTeams());
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const {
                id,
                name,
                description,
                category,
                sport,
                initialDate,
                endDate,
              } = newData;
              if (
                !id ||
                !name ||
                !description ||
                !category ||
                !sport ||
                !initialDate ||
                !endDate
              ) {
                reject();
              } else {
                updateTeam({
                  id,
                  name,
                  description,
                  category: category.id,
                  sport: sport.id,
                  initialDate: moment(initialDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                }).then(() => {
                  setSuccessMsg('Equipo actualizado correctamente')
                  resolve();
                })
                .finally(()=> fetchTeams());
              }
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              deleteTeam(oldData.id)
              .then(res => {
                setSuccessMsg('Equipo eliminado correctamente')
                resolve();
              })
              .finally(()=> fetchTeams());
            }),
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  teams: teamsSelector(state),
  categories: categoriesSelector(state),
  sports: sportsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...teamActions, ...categoryActions, ...sportActions, setSuccessMsg },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(TeamList);
