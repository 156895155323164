import { requests } from '../utils/requestHandler';

export const anthropometricDataService = {
    getAllAnthropometricData: () => requests.get(`/anthropometric/`),
    getAllStage: () => requests.get('/stage/'),
    getAllMadurityIndex: () => requests.get('/madurity_index/'),
    getAllPurpose: () => requests.get(`/purpose/`),
    getAnthropometricPlayer: (id) => requests.get(`/anthropometric/person/?idPerson=${id}&last=False`),
    updateAnthropometricData: (id, AD) => requests.put(`/anthropometric/${id}/`, AD),
    saveAnthropometricData: (AD) => requests.post(`/anthropometric/`, AD),
    deleteAnthropometricData: (id) => requests.delete(`/anthropometric/${id}/`),
};