import { memberService } from '../../services/member.service';
import { MEMBER_ACTION_TYPES } from './member.types';

export const addMember = member => async dispatch => {
    try {
        const response = await memberService.addMember(member);
        response
          ? dispatch({
              type: MEMBER_ACTION_TYPES.ADD_MEMBER_SUCCESS,
              payload: response
            })
          : dispatch({
              type: MEMBER_ACTION_TYPES.ADD_MEMBER_ERROR,
            });
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
  };
  
  export const updateMember = member => async dispatch => {
    try {
        const response = await memberService.updateMember(member.id, member);
        response
          ? dispatch({
              type: MEMBER_ACTION_TYPES.UPDATE_MEMBER_SUCCESS,
              payload: response
            })
          : dispatch({
              type: MEMBER_ACTION_TYPES.UPDATE_MEMBER_ERROR,
            });
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
  };
  
  export const deleteMember = id => async dispatch => {
    try {
        const response = await memberService.deleteMember(id);
        response
          ? dispatch({
              type: MEMBER_ACTION_TYPES.DELETE_MEMBER_SUCCESS,
              payload: response
            })
          : dispatch({
              type: MEMBER_ACTION_TYPES.DELETE_MEMBER_ERROR,
            });
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
  };