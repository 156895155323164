import { positionService } from '../../services/position.service';
import { POSITION_ACTION_TYPES } from './position.types';

export const fetchPositions = () => async dispatch => {
    try {
        const response = await positionService.getPositions();
        response
          ? dispatch({
              type: POSITION_ACTION_TYPES.FETCH_TEAM_POSITION_SUCCESS,
              payload: response
            })
          : dispatch({
              type: POSITION_ACTION_TYPES.FETCH_TEAM_POSITION_ERROR,
            });
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
  };