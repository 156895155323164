import { STATS_ACTION_TYPES } from './stats.types';

const initialState = {
  teamRPE: [],
  teamRPEWeek: [],
  teamWellness: [],
};

export default function stats (state = initialState, action) {
  switch (action.type) {
    case STATS_ACTION_TYPES.FETCH_TEAM_RPE_SUCCESS:
      return Object.assign({}, state, {
        teamRPE: action.payload,
      });
    case STATS_ACTION_TYPES.FETCH_TEAM_RPE_WEEK_SUCCESS:
      return Object.assign({}, state, {
        teamRPEWeek: action.payload,
      });  
    case STATS_ACTION_TYPES.FETCH_WELLNESS_TEAM:
      return Object.assign({}, state, {
        teamWellness: action.payload,
      });
    default:
      return state;
  }
}
