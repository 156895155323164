import { CATEGORY_ACTION_TYPES } from './category.types';

const initialState = {
  categories: [],
  error: '',
};

export default function category(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: action.payload,
      });
    case CATEGORY_ACTION_TYPES.FETCH_CATEGORIES_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
