import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as statsActions from '../store/stats/stats.actions';
import * as teamActions from '../store/team/team.actions';
import { fetchPositions } from '../store/position/position.actions';
import { teamRPESelector, teamRPEWeekSelector } from '../store/selectors';
import CustomLineBarChartWithLegend from '../components/charts/barLineChart/CustomLineBarChartWithLegend';
import { positionsSelector } from '../store/selectors';
import LoadingSpinner from '../components/LoadingSpinner';

const RpeStats = ({
  fetchTeamRPE,
  teamRPE,
  fetchTeamRPEByPosition,
  fetchPositions,
  positions,
  id,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTeamRPE(id);
    fetchPositions();  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [allTeamRPEByPosition, setAllTeamRPEByPosition] = useState([]);

  /**
   * Retorna un arreglo de promesas con los datos necesarios para los graficos
   * por posición. (position y data)
   * @param positionsList
   * @param filters Si está presente seran los filtros aplicados, si no utiliza los default
   * @returns {*}
   */
  const getRPEByPositionPromises = (positionsList, filters = {}) => {
    const { days, offsetCl, cantDaysAl, cantDaysMonotony } = filters;
    return positionsList.map(position => {
      return fetchTeamRPEByPosition(
        id,
        position.description,
        days,
        offsetCl,
        cantDaysAl,
        cantDaysMonotony
      ).then(res => {
        return {
          position,
          data: res,
        };
      });
    });
  };

  const onRPEReload = filters => {
    const { days, offsetCl, cantDaysAl, cantDaysMonotony } = filters;
    setLoading(true);
    Promise.all(getRPEByPositionPromises(positions, filters))
      .then(responses => {
        setAllTeamRPEByPosition(responses);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    return fetchTeamRPE(id, days, offsetCl, cantDaysAl, cantDaysMonotony);
  };

  /**
   * Cuando se tienen las posiciones, se piden los RPE por cada una y luego
   * se utilizan las respuestas combinadas para mostrar los graficos
   */
  useEffect(() => {
    if (positions && positions.length > 0) {
      Promise.all(getRPEByPositionPromises(positions))
        .then(responses => {
          setAllTeamRPEByPosition(responses);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  return (
    <div>
      {teamRPE && (
        <div style={{ padding: '10px 0px' }}>
          <CustomLineBarChartWithLegend
            title="Carga interna / Carga externa"
            data={teamRPE}
            type="RPE"
            hasFilters={true}
            onReload={onRPEReload}
          />
        </div>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {allTeamRPEByPosition.map(teamRPEByPosition => {
            return (
              <div
                style={{ padding: '10px 0px' }}
                key={teamRPEByPosition.position.id}
              >
                <CustomLineBarChartWithLegend
                  type="RPE"
                  title={`Carga interna / Carga externa - Posición: ${teamRPEByPosition.position.description}`}
                  data={teamRPEByPosition.data}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  teamRPE: teamRPESelector(state),
  teamRPEWeek: teamRPEWeekSelector(state),
  positions: positionsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...statsActions, ...teamActions, fetchPositions }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(RpeStats);
