import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ImageUpload = ({ onImageChange, fileName }) => {
  const classes = useStyles();

  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: 4 / 4,
  });

  const [src, setSrc] = useState(null);
  const [imageRef, setImageRef] = useState(null);

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = image => {
    setImageRef(image);
    makeClientCrop();
  };

  const onCropComplete = crop => {
    makeClientCrop();
  };

  const onCropChange = (newCrop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(newCrop);
  };

  /**
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  }

  async function makeClientCrop() {
    if (imageRef && crop.width && crop.height) {
      const newCroppedImage = await getCroppedImg(
        imageRef,
        crop,
        `${fileName}.jpeg`
      );
      onImageChange(newCroppedImage);
    }
  }

  return (
    <div className={classes.container}>
      <Button style={{margin: '10px'}} variant="contained" component="label">
        Subir imagen
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={onSelectFile}
          accept="image/*"
        />
      </Button>
      {src && (
        <ReactCrop
          src={src}
          crop={crop}
          ruleOfThirds
          keepSelection
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
    </div>
  );
};

export default ImageUpload;
