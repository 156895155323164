import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import Home from '../screens/Home';
import TeamList from '../screens/TeamList';
import PeopleList from '../screens/PeopleList';
import LoginScreen from '../screens/LoginScreen';
import SquadList from '../screens/Squadlist';
import SquadDetail from '../screens/SquadDetail';
import Profile from '../screens/Profile';
import SquadStats from '../screens/SquadStats'
import PrivacyPolicy from './PrivacyPolicy';
import Injuries from '../screens/Injuries';
import AnthropometricDataScreen from '../screens/AnthropometricDataScreen';

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => state.auth.isSignedIn === true || !!state.auth.token,
  // Returns true if the user authtate is loading
  // authenticatingSelector: state => state.auth.isLoading === true,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  // AuthenticatingComponent: LoadingSpinner,
});

const ViewsContainer = () => (
  <Switch>
    <Route exact path="/" component={userIsAuthenticated(Home)} />
    <Route path="/legal/privacy-policy" component={PrivacyPolicy} />
    <Route path="/login" component={LoginScreen} />
    <Route exact path="/teams" component={userIsAuthenticated(TeamList)} />
    <Route exact path="/people" component={userIsAuthenticated(PeopleList)} />
    <Route exact path="/squads" component={userIsAuthenticated(SquadList)} />
    <Route
      path="/squads/:id/stats"
      component={userIsAuthenticated(SquadStats)}
    />
    <Route path="/squads/:id" component={userIsAuthenticated(SquadDetail)} />
    <Route
      path="/profile/:id/:idPerson"
      component={userIsAuthenticated(Profile)}
    />
    <Route path="/injuries" component={userIsAuthenticated(Injuries)} />
    <Route
      path="/anthropometricdata"
      component={userIsAuthenticated(AnthropometricDataScreen)}
    />
    <Redirect from="/" to="/" />
  </Switch>
);

export default ViewsContainer;
