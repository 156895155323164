import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CreateIcon from '@material-ui/icons/Create';
import CardActions from '@material-ui/core/CardActions';
import './css/skillsCard.css';
import { ButtonBase } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    maxHeight: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const Notes = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={3}>
      <CardHeader
        title={title.toUpperCase()}
        subheader={subtitle}
        style={{ textAlign: 'left' }}
        titleTypographyProps={{ variant: 'h6' }}
      />

      <CardActions style={{ justifyContent: 'flex-end' }}>
        <div
          style={{
            border: '1px solid black',
            boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.3)',
          }}
        >
          <ButtonBase>
            <CreateIcon fontSize="large" />
          </ButtonBase>
        </div>
      </CardActions>
    </Card>
  );
};

Notes.defaultProps = {
  title: '',
  subtitle: '',
};

Notes.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Notes;
