const initialState = {
  notifications: [],
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case 'ENQUEUE_SNACKBAR':
      return Object.assign({}, state, {
        notifications: [...state.notifications, action.notification],
      });
    case 'REMOVE_SNACKBAR':
      return Object.assign({}, state, {
        notifications: state.notifications.filter(
          noti => noti.key !== action.key
        ),
      });
    default:
      return state;
  }
}
