import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ThemeProvider } from '@material-ui/styles';
import theme from './styles/theme';
import AppWrapper from './components/wrappers/AppWrapper';
import { SnackbarProvider } from 'notistack';
import Notifier from './store/notification/Notifier';

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <Notifier />
        <AppWrapper />
      </SnackbarProvider>
    </Provider>
  </ThemeProvider>
);

export default App;
