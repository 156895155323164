import { PERSON_ACTION_TYPES } from './person.types';
import { personService } from '../../services/person.service';

export const fetchPeople = () => async dispatch => {
  try {
    const response = await personService.getPeople();
    response
      && dispatch({
          type: PERSON_ACTION_TYPES.FETCH_PEOPLE_SUCCESS,
          payload: response
        })
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const addPerson = person => async dispatch => {
  try {
    const response = await personService.addPerson(person);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const updatePerson = person => async dispatch => {
  try {
    const response = await personService.updatePerson(person);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deletePerson = id => async dispatch => {
  try {
    const response = await personService.deletePerson(id);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadPersonImage = (id, image) => async dispatch => {
  let formData = new FormData();
  formData.append('image', image, image.name);
  try {
    const response = await personService.uploadPersonImage(id, formData);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
