import { errorHandler } from './errorHandler';
import { API_BASE_URL, BASE_URL, LOGIN_BASE_URL } from '../constants/config';
import { store } from '../store/store';
import { setError } from '../store/app/app.actions';

export const runFetch = (url, params = {}) => {
  const baseUrl = url.includes('login/') ? `${LOGIN_BASE_URL}/` : API_BASE_URL;
  const isAuth = url.includes('rest-auth/');
  const token = localStorage.getItem('token');
  const completeUrl = isAuth ? `${BASE_URL}${url}` : `${baseUrl}${url}`;
  if (token) {
    params.headers
      ? (params.headers.Authorization = `Token ${localStorage.getItem(
          'token'
        )}`)
      : (params.headers = {
          Authorization: `Token ${localStorage.getItem('token')}`,
        });
  }
  return fetch(completeUrl, params)
    .then(response => {
      if (response.ok) {
        return response.status !== 204
          ? Promise.resolve(response.json())
          : Promise.resolve(true);
      }
      return errorHandler(response).then(error => {
        store.dispatch(setError(error));
        return false;
      });
    })
    .catch(error => {
      return errorHandler(error).then(msg => {
        return Promise.reject(msg);
      });
    });
};

export const runFetchFile = (url, params = {}) => {
  return fetch(API_BASE_URL + url, params)
    .then(response => {
      if (response.ok) {
        return Promise.resolve(response);
      }
      return errorHandler(response).then(error => {
        // store.dispatch(setError(error));
        return false;
      });
    })
    .then(response => response.blob())
    .catch(error => {
      return errorHandler(error).then(msg => {
        return Promise.reject(msg);
      });
    });
};
