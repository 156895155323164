import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { useFormik } from 'formik';

/*material ui*/
import TextField from '@material-ui/core/TextField';
import {
  makeStyles,
  Typography,
  Button,
  Grid,
  Radio,
  RadioGroup,
  Select,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  MenuItem,
  FormHelperText,
  Card,
  CardContent,
} from '@material-ui/core';

import * as injuryActions from '../store/injury/injury.actions';
import * as teamActions from '../store/team/team.actions';
import { myTeamsSelector, teamPlayersSelector } from '../store/selectors';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
  },

  small: {
    fontSize: 'small',
  },

  textField: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
    width: 300,
  },

  radioGroup: {
    marginTop: theme.spacing(3),
  },

  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  root: {
    minWidth: 275,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const InjuryForm = ({
  edit,
  onSubmit,
  title,
  buttonTitle,
  setInjury,
  addInjury,
  fetchMyTeams,
  myTeams,
  fetchTeamPlayers,
  players,
  injury,
  setMostrarForm,
}) => {
  const classes = useStyles();

  const [opcionesEquipos, setOpcionesEquipos] = useState([]);
  const [opcionesJugadores, setOpcionesJugadores] = useState([]);
  const [mostrar, setMostrar] = useState(false);
  const [fechaLesion, setFechaLesion] = useState(edit ? injury.date : '');

  useEffect(() => {
    fetchMyTeams(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const options = [];
    const filteredOptions = [];
    if (myTeams.physicalStaff) {
      myTeams.physicalStaff.forEach(team => {
        options.push(team);
      });
    }
    if (myTeams.medicalStaff) {
      myTeams.medicalStaff.forEach(team => {
        options.push(team);
      });
    }
    const filtered = options.filter(
      (v, i, a) => a.findIndex(t => t.id === v.id) === i
    );
    filtered.forEach(team =>
      filteredOptions.push(
        <MenuItem key={team.id} value={team.id}>
          {team.name}
        </MenuItem>
      )
    );
    setOpcionesEquipos(filteredOptions);
  }, [myTeams]);

  useEffect(() => {
    if (players) {
      setOpcionesJugadores(
        players.map(player => {
          return (
            <MenuItem key={player.person.id} value={player.person.id}>
              {`${player.person.firstName} ${player.person.lastName}`}
            </MenuItem>
          );
        })
      );
    }
  }, [players]);

  useEffect(() => {
    if (injury) {
      fetchTeamPlayers(injury.teams[0].id);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [injury]);

  const handleEquipoChange = id => {
    fetchTeamPlayers(id);
  };

  const handleDateState = value => {
    setFechaLesion(value);
  };

  const returnDateValidation = value => {
    let error;
    if (value) {
      if (value < fechaLesion) {
        error = 'La fecha no debe ser anterior a la fecha de lesión';
      }
      return error;
    }
  };

  const fieldValidation = value => {
    let error;
    if (!edit) {
      if (!value) {
        error = 'Este campo es obligatorio';
      }
    }
    return error;
  };

  const injuryDateValidation = value => {
    const today = new Date().toISOString().replace(/\T.*/, ''); //eslint-disable-line
    let error;
    if (!value) {
      error = 'Este campo es obligatorio';
    } else if (value > today) {
      error = 'Fecha inválida';
    }
    return error;
  };

  const validate = values => {
    const errors = {};
    let error = fieldValidation(values.person);
    if (error) {
      errors.person = error;
    }
    error = fieldValidation(values.description);
    if (error) {
      errors.description = error;
    }
    error = injuryDateValidation(values.date);
    if (error) {
      errors.date = error;
    }
    error = fieldValidation(values.type);
    if (error) {
      errors.type = error;
    }
    error = fieldValidation(values.state);
    if (error) {
      errors.state = error;
    }
    error = returnDateValidation(values.returnToPlayDate);
    if (error) {
      errors.returnToPlayDate = error;
      setMostrar(true);
    }
    error = returnDateValidation(values.returnToTeam);
    if (error) {
      errors.returnToTeam = error;
      setMostrar(true);
    }
    error = returnDateValidation(values.returnToTrainingDate);
    if (error) {
      errors.returnToTrainingDate = error;
      setMostrar(true);
    }
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: edit ? injury.date : '',
      person: edit ? injury.person.id : '',
      team: edit ? injury.teams[0].id : '',
      description: edit ? injury.description : '',
      returnToTrainingDate:
        edit && injury.returnToTrainingDate ? injury.returnToTrainingDate : '',
      returnToTeam: edit && injury.returnToTeam ? injury.returnToTeam : '',
      returnToPlayDate:
        edit && injury.returnToPlayDate ? injury.returnToPlayDate : '',
      type: edit ? injury.type : '',
      state: edit ? injury.state : '',
    },
    onSubmit: onSubmit,
    validate,
  });

  return (
    <Card className={classes.root} id="injury-form">
      <CardContent>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          {title}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              {!edit && (
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Equipo
                    </InputLabel>
                    <Select
                      {...formik.getFieldProps('team')}
                      disabled={edit}
                      labelId="demo-simple-select-outlined-label"
                      label="Equipo"
                      style={{ width: '300px' }}
                      onChange={event => {
                        formik.setFieldValue('team', event.target.value);
                        handleEquipoChange(event.target.value);
                      }}
                    >
                      {opcionesEquipos}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={edit ? 12 : 6}>
                <FormControl
                  error={formik.touched.person && !!formik.errors.person}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Jugador
                  </InputLabel>
                  <Select
                    {...formik.getFieldProps('person')}
                    disabled={edit}
                    labelId="demo-simple-select-outlined-label"
                    label="Jugadores"
                    style={{ width: '300px' }}
                  >
                    {opcionesJugadores}
                  </Select>
                  <FormHelperText>
                    {formik.touched.person ? formik.errors.person : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={edit}
                  error={
                    formik.touched.description && !!formik.errors.description
                  }
                  helperText={
                    formik.touched.description ? formik.errors.description : ''
                  }
                  id="descripcion-lesion"
                  label="Descripcion básica de la lesión"
                  type="text"
                  placeholder="Ej: Traumatismo miembro inferior derecho"
                  className={classes.textField}
                  {...formik.getFieldProps('description')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...formik.getFieldProps('date')}
                  onBlur={e => handleDateState(e.target.value)}
                  error={formik.touched.date && !!formik.errors.date}
                  helperText={formik.touched.date ? formik.errors.date : ''}
                  label="Fecha de lesión"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <FormControl
                  component="fieldset"
                  error={formik.touched.type && !!formik.errors.type}
                  style={{ marginTop: '30px' }}
                >
                  <FormLabel component="legend">
                    Qué tipo de lesión sufrió el jugador
                  </FormLabel>
                  <RadioGroup {...formik.getFieldProps('type')}>
                    <FormControlLabel
                      disabled={edit}
                      value="traumatic"
                      control={<Radio color="primary" />}
                      label="Traumática"
                    />
                    <FormControlLabel
                      disabled={edit}
                      value="no_traumatic"
                      control={<Radio color="primary" />}
                      label="No traumática"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {formik.touched.type ? formik.errors.type : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  component="fieldset"
                  error={formik.touched.state && !!formik.errors.state}
                  style={{ marginTop: '30px' }}
                >
                  <FormLabel component="legend">Estado de la lesión</FormLabel>
                  <RadioGroup {...formik.getFieldProps('state')}>
                    <FormControlLabel
                      disabled={edit}
                      value="active"
                      control={<Radio color="primary" />}
                      label="Activa"
                    />
                    <FormControlLabel
                      disabled={edit}
                      value="no_active"
                      control={<Radio color="primary" />}
                      label="No activa"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {formik.touched.state ? formik.errors.state : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {mostrar ? (
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    size="small"
                    className={classes.button}
                    color="primary"
                    onClick={() => setMostrar(false)}
                  >
                    Ver menos
                  </Button>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...formik.getFieldProps('returnToTrainingDate')}
                      error={
                        formik.touched.returnToTrainingDate &&
                        !!formik.errors.returnToTrainingDate
                      }
                      helperText={
                        formik.touched.returnToTrainingDate
                          ? formik.errors.returnToTrainingDate
                          : ''
                      }
                      label="Fecha de retorno al entrenamiento"
                      type="date"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...formik.getFieldProps('returnToTeam')}
                      error={
                        formik.touched.returnToTeam &&
                        !!formik.errors.returnToTeam
                      }
                      helperText={
                        formik.touched.returnToTeam
                          ? formik.errors.returnToTeam
                          : ''
                      }
                      label="Fecha de retorno al equipo"
                      type="date"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...formik.getFieldProps('returnToPlayDate')}
                    error={
                      formik.touched.returnToPlayDate &&
                      !!formik.errors.returnToPlayDate
                    }
                    helperText={
                      formik.touched.returnToPlayDate
                        ? formik.errors.returnToPlayDate
                        : ''
                    }
                    label="Fecha de retorno a partidos"
                    type="date"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} direction="row" alignItems="center">
                <Button
                  size="small"
                  color="primary"
                  className={classes.button}
                  onClick={() => setMostrar(true)}
                >
                  Ver más opciones
                </Button>
              </Grid>
            )}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                type="reset"
                size="small"
                color="primary"
                disabled={formik.isSubmitting}
                className={classes.button}
                onClick={() => {
                  setInjury(null);
                  setMostrar(false);
                  setMostrarForm(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
              >
                {buttonTitle}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => ({
  myTeams: myTeamsSelector(state),
  players: teamPlayersSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...injuryActions,
      ...teamActions,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InjuryForm
);
