import { GENDER_ACTION_TYPES } from './gender.types';

const initialState = {
  genders: [],
  error: '',
};

export default function gender(state = initialState, action) {
  switch (action.type) {
    case GENDER_ACTION_TYPES.FETCH_GENDERS_SUCCESS:
      return Object.assign({}, state, {
        genders: action.payload,
      });
    case GENDER_ACTION_TYPES.FETCH_GENDERS_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
