export const translations = {
  header: {
    actions: 'Acciones',
  },
  body: {
    emptyDataSourceMessage: 'No hay datos para mostrar',
    addTooltip: 'Agregar',
    editTooltip: 'Editar',
    deleteTooltip: 'Eliminar',
    filterRow: {
      filterTooltip: 'Filtrar',
    },
    editRow: {
      deleteText: '¿Está seguro que desea borrar el elemento?',
      saveTooltip: 'Aceptar',
      cancelTooltip: 'Cancelar',
    },
  },
  toolbar: {
    showColumnsTitle: 'Ver columnas',
    addRemoveColumns: 'Agregar o quitar columnas',
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
  },
  pagination: {
    labelRowsSelect: 'filas',
    firstTooltip: 'Primera página',
    lastTooltip: 'Última página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Página siguiente',
    labelDisplayedRows: '{from}-{to} de {count}',
  },
};
