import { ROLE_ACTION_TYPES } from './role.types';

const initialState = {
  teamRoles: [],
};

export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case ROLE_ACTION_TYPES.FETCH_TEAM_ROLES_SUCCESS:
      return {
        ...state,
        teamRoles: action.payload,
      };
    default:
      return state;
  }
}
