export const PROFILE_ACTION_TYPES = {
    FETCH_TEAM_PLAYER_SUCCESS: 'FETCH_TEAM_PLAYER_SUCCESS',
    FETCH_RPE_PLAYER_SUCCESS: 'FETCH_RPE_PLAYER_SUCCESS',
    FETCH_SKILL_CATEGORY_PLAYER_SUCCESS: 'FETCH_SKILL_CATEGORY_PLAYER_SUCCESS',
    FETCH_TECHNICAL_ATTRIBUTES_PLAYER_SUCCESS: 'FETCH_TECHNICAL_ATTRIBUTES_PLAYER_SUCCESS',
    FETCH_WELLNESS_PLAYER_SUCCESS: 'FETCH_WELLNESS_PLAYER_SUCCESS',
    FETCH_WELLNESS_HISTORICAL_PLAYER_SUCCESS: 'FETCH_WELLNESS_HISTORICAL_PLAYER_SUCCESS',
    FETCH_TEAM_PLAYER_ERROR: 'FETCH_TEAM_PLAYER_ERROR',
    FETCH_RPE_PLAYER_ERROR: 'FETCH_RPE_PLAYER_ERROR',
    FETCH_SKILL_CATEGORY_PLAYER_ERROR: 'FETCH_SKILL_CATEGORY_PLAYER_ERROR',
    FETCH_TECHNICAL_ATTRIBUTES_PLAYER_ERROR: 'FETCH_TECHNICAL_ATTRIBUTES_PLAYER_ERROR',
    FETCH_WELLNESS_PLAYER_ERROR: 'FETCH_WELLNESS_PLAYER_ERROR',
    FETCH_WELLNESS_HISTORICAL_PLAYER_ERROR: 'FETCH_WELLNESS_HISTORICAL_PLAYER_ERROR',
    TEAM_ERROR: 'TEAM_ERROR',
  };