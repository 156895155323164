import { requests } from '../utils/requestHandler';

export const injuryService = {
    getInjuries: () => requests.get('/injury/'),
    // not used: getTeamInjuries: (id) => requests.get(`/injury/players/?idTeam=${id}&injured=true`),
    addInjury: (injury) => requests.post(`/injury/`, injury),
    updateInjury: (injury, id) => requests.put(`/injury/${id}/`, injury),
    deleteInjury: (id) => requests.delete(`/injury/${id}/`),
    getInjuryObservations: (id) => requests.get(`/observation/?idInjury=${id}`),
    saveInjuryObservation: (observation) => requests.post('/observation/', observation),
};