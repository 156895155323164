// Default chart values
export const DEFAULT_DAYS = {
  VALUE: 28,
  MIN: 1,
  MAX: 100,
};
export const DEFAULT_OFFSET_CL = {
  VALUE: 7,
  MIN: 1,
  MAX: 100,
};
export const DEFAULT_DAYS_AL = {
  VALUE: 7,
  MIN: 1,
  MAX: 100,
};
export const DEFAULT_DAYS_MONOTONY = {
  VALUE: 7,
  MIN: 1,
  MAX: 100,
};
export const DEFAULT_WEEKS = {
  VALUE: 7, 
  MIN: 2,
  MAX: 52,
};
export const DEFAULT_START_WEEK = {
  VALUE: 'Monday',
  OTHER: 'Sunday', 
};