import { PROFILE_ACTION_TYPES } from './profile.types';

const initialState = {
  teamPlayer: [],
  RPEPlayer: [],
  error: '',
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.FETCH_TEAM_PLAYER_SUCCESS:
      return Object.assign({}, state, {
        teamPlayer: action.payload,
      });
    case PROFILE_ACTION_TYPES.FETCH_RPE_PLAYER_SUCCESS:
      return Object.assign({}, state, {
        RPEPlayer: action.payload,
      });
    case PROFILE_ACTION_TYPES.FETCH_WELLNESS_PLAYER:
      return Object.assign({}, state, {
        wellness: action.payload,
      });
    case PROFILE_ACTION_TYPES.FETCH_ANTHROPOMETRIC_PLAYER:
      return Object.assign({}, state, {
        anthropometric: action.payload,
      });
    case PROFILE_ACTION_TYPES.TEAM_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
