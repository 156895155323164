import React, { useEffect, useState } from 'react';
import { fetchWellnessTeam } from '../store/stats/stats.actions';
import { teamWellnessSelector } from '../store/selectors';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import TableStats from './TableStats';
import LoadingSpinner from '../components/LoadingSpinner';

const WellnessStats = ({ id, fetchWellnessTeam, teamWellness, goToProfile }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchWellnessTeam(id).then(() => setLoading(false)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  return (
    <div>
      {loading ? (
        <LoadingSpinner/>
      ) : (
        <TableStats allMembersWellness={teamWellness} goToProfile={goToProfile}/>
      )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  teamWellness: teamWellnessSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWellnessTeam,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(WellnessStats);