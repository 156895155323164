export const setError = msg => dispatch => {
  dispatch({
    type: 'ENQUEUE_SNACKBAR',
    notification: {
      message: msg,
      key: new Date().getTime() + Math.random(),
      options: { variant: 'error' },
    },
  });
};

export const setSuccessMsg = msg => dispatch => {
  dispatch({
    type: 'ENQUEUE_SNACKBAR',
    notification: {
      message: msg,
      key: new Date().getTime() + Math.random(),
      options: { variant: 'success' },
    },
  });
};

export const resetError = () => dispatch => {
  dispatch(setError(null));
};
