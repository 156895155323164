import React from 'react';
import PropTypes from 'prop-types';
import './css/skillsCard.css';
import { Typography, createTheme, ThemeProvider } from '@material-ui/core';

import { Card, CardHeader, CardActions, Grid } from '@material-ui/core';

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiCardHeader: {
      // Name of the rule
      title: {
        // Some CSS
        fontWeight: 600,
      },
    },
  },
});

const calculateAge = age => {
  const birthDate = new Date(age);
  const today = new Date();
  const month = today.getMonth();
  const day = today.getDate();
  const year = today.getFullYear();

  today.setDate(day);
  today.setMonth(month);
  today.setFullYear(year);

  return Math.floor((today - birthDate) / (1000 * 60 * 60 * 24) / 365);
};

const PhysicalAttributes = ({
  title,
  subtitle,
  weight,
  height,
  muscleMassKg,
  muscleMassPercentage,
  age,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Card elevation={2}>
        <CardHeader
          titleTypographyProps={{ variant: 'h6' }}
          title={title.toUpperCase()}
          subheader={subtitle}
          style={{ textAlign: 'center' }}
        />
        <CardActions style={{ justifyContent: 'space-between' }}>
          <Grid container>
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Peso
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {weight}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Altura
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {height}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              {(muscleMassKg || muscleMassPercentage) && (
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Masa Muscular
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {muscleMassKg && `${muscleMassKg} Kg`}{' '}
                      {muscleMassPercentage && muscleMassKg && ` | `}
                      {muscleMassPercentage && `${muscleMassPercentage} %`}
                    </div>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={muscleMassKg || muscleMassPercentage ? 6 : 12}>
                <Typography variant="h6" gutterBottom>
                  Edad
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {calculateAge(age)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
};

PhysicalAttributes.defaultProps = {
  muscleUpIcon: false,
  title: '',
  subtitle: '',
  weight: '',
  height: '',
  muscle: '',
  age: '',
};

PhysicalAttributes.propTypes = {
  muscleUpIcon: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  weight: PropTypes.string,
  height: PropTypes.string,
  muscleMassKg: PropTypes.string,
  muscleMassPercentage: PropTypes.string,
  age: PropTypes.number,
};

export default PhysicalAttributes;
