import { requests } from '../utils/requestHandler';

export const teamService = {
    getTeams: () => requests.get('/team/'),
    getMyTeams: () => requests.get('/team/my_teams/'),
    getTeam: (id) => requests.get(`/team/${id}/`),
    getTeamPlayers: (id) => requests.get(`/team/${id}/players/`),
    getTeamMembers: (id) => requests.get(`/team/${id}/members/`),
    getTeamPositions: (id) => requests.get(`/team/${id}/team_positions/`),
    addTeam: (team) => requests.post('/team/', team),
    updateTeam: (id, team) => requests.put(`/team/${id}/`, team),
    deleteTeam: (id) => requests.delete(`/team/${id}`),
};