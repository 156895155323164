export const ANTHROPOMETRIC_DATA_ACTION_TYPES = {
  SET_ANTHROPOMETRIC_DATA: 'SET_ANTHROPOMETRIC_DATA',
  FETCH_ANTHROPOMETRIC_DATA_SUCCESS: 'FETCH_ANTHROPOMETRIC_DATA_SUCCESS',
  FETCH_ANTHROPOMETRIC_DATA_ERROR: 'FETCH_ANTHROPOMETRIC_DATA_ERROR',
  FETCH_ANTHROPOMETRIC_PLAYER_SUCCESS: 'FETCH_ANTHROPOMETRIC_PLAYER_SUCCESS',
  FETCH_ANTHROPOMETRIC_PLAYER_ERROR: 'FETCH_ANTHROPOMETRIC_PLAYER_ERROR',
  UPDATE_ANTHROPOMETRIC_DATA_SUCCESS: 'UPDATE_ANTHROPOMETRIC_DATA_SUCCESS',
  UPDATE_ANTHROPOMETRIC_DATA_ERROR: 'UPDATE_ANTHROPOMETRIC_DATA_ERROR',
  SAVE_ANTHROPOMETRIC_DATA_SUCCESS: 'SAVE_ANTHROPOMETRIC_DATA_SUCCESS',
  SAVE_ANTHROPOMETRIC_DATA_ERROR: 'SAVE_ANTHROPOMETRIC_DATA_ERROR',
  DELETE_ANTHROPOMETRIC_DATA_SUCCESS: 'DELETE_ANTHROPOMETRIC_DATA_SUCCESS',
  DELETE_ANTHROPOMETRIC_DATA_ERROR: 'DELETE_ANTHROPOMETRIC_DATA_ERROR',
  FETCH_ALL_STAGE_SUCCESS: 'FETCH_ALL_STAGE_SUCCESS',
  FETCH_ALL_STAGE_ERROR: 'FETCH_ALL_STAGE_ERROR',
  FETCH_ALL_MADURITY_INDEX_SUCCESS: 'FETCH_ALL_MADURITY_INDEX_SUCCESS',
  FETCH_ALL_MADURITY_INDEX_ERROR: 'FETCH_ALL_MADURITY_INDEX_ERROR',
  FETCH_ALL_PURPOSE_SUCCESS: 'FETCH_ALL_PURPOSE_SUCCESS',
  FETCH_ALL_PURPOSE_ERROR: 'FETCH_ALL_PURPOSE_ERROR',
};
