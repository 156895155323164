import { SYSTEM_ACTION_TYPES } from './system.types';

const initialState = {
  systemTag: '',
};

export default function system(state = initialState, action) {
  switch (action.type) {
    case SYSTEM_ACTION_TYPES.FETCH_SYSTEM_TAG_SUCCESS:
      return Object.assign({}, state, {
        systemTag: action.payload.tag,
      });
    default:
      return state;
  }
}
