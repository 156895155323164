import {INJURY_ACTION_TYPES} from './injury.types';

const initialState = {
  injuries: [],
  error: '',
};

export default function injury(state = initialState, action) {
  switch (action.type) {
    case INJURY_ACTION_TYPES.FETCH_INJURIES_SUCCESS:
      return Object.assign({}, state, {
        injuries: action.payload,
      });
    default:
      return state;
  }
}
