import { nationalityService } from '../../services/nationality.service';
import { NATIONALITY_ACTION_TYPES } from './nationality.types';

export const fetchNationalities = () => async dispatch => {
  try {
    const response = await nationalityService.getNationality();
    response
      ? dispatch({
          type: NATIONALITY_ACTION_TYPES.FETCH_NATIONALITIES_SUCCESS,
          payload: response
        })
      : dispatch({
          type: NATIONALITY_ACTION_TYPES.FETCH_NATIONALITIES_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
