import { CATEGORY_ACTION_TYPES } from './category.types';
import { categoryService } from '../../services/category.service';

export const fetchCategories = () => async dispatch => {
  try {
    const response = await categoryService.getCategories();
    response
      ? dispatch({
          type: CATEGORY_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS,
          payload: response
        })
      : dispatch({
          type: CATEGORY_ACTION_TYPES.FETCH_CATEGORIES_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
