import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Fab,
  Tooltip,
  TextField,
  CardActions,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  NavigateBefore,
  NavigateNext,
  Clear,
  Done,
  Add,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { fetchInjuryObservations, addInjuryObservation } from '../../store/injury/injury.actions';
import LoadingSpinner from '../../components/LoadingSpinner';
import { setSuccessMsg } from '../../store/app/app.actions';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    justifyContent: 'center',
    paddingTop: '0',
  },
  actionButtons: {
    justifyContent: 'flex-end',
  },
  addButton: {
    boxShadow: 'none',
    width: '35px',
    height: '35px',
  },
  addButtonContainer: {
    paddingTop: '0',
  },
  title: {
    paddingBottom: '0',
  },
  descriptionInput: {
    width: '100%',
  },
  cardContainer: {
    marginBottom: '3%',
  },
  dialog: {
    width: '100vw',
    zIndex: '5',
    height: '80vh',
    marginTop: '15vh',
  },
}));

const ObservacionesModal = ({
  open,
  onClose,
  injuryId,
  fetchInjuryObservations,
  addInjuryObservation,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const isMounted = useRef(true);
  const [maxPage, setMaxPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [observations, setObservations] = useState([]);
  const [newObservation, setNewObservation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [touched, setTouched] = useState(false);
  const [description, setDescription] = useState('');
  const theme = useTheme();
  const min = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (injuryId) {
      setLoading(true);
      fetchInjuryObservations(injuryId)
        .then((data) => isMounted && setObservations(data))
        .finally(() => setLoading(false));
    }
    return () => (isMounted.current = false);
    // eslint-disable-next-line
  }, [injuryId]);

  useEffect(() => {
    observations.length > 0 &&
      setMaxPage(Math.ceil(observations.length / 3) - 1);
  }, [observations]);

  useEffect(() => {
    if (!open) {
      if (observations.length > 0 && !observations[0].descripcion) {
        const o = [...observations];
        o.shift();
        setObservations(o);
      }
      resetValues();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const resetValues = () => {
    setTouched(false);
    setSubmitting(false);
    setNewObservation(false);
    setDescription('');
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const addNewObservation = (observation) => {
    let o = [...observations];
    o.shift();
    setObservations([observation, ...o]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const observation = {
      description: description,
      date: format(new Date(), 'yyyy-MM-dd'),
      injury: injuryId,
    };
    addInjuryObservation(observation)
      .then((res) => {
        setSuccessMsg('Observacion agregada correctamente');
        addNewObservation(observation);
      })
      .finally(() => {
        resetValues();
      });
  };

  const handleNewObservation = () => {
    setNewObservation(true);
    setObservations([
      {
        date: format(new Date(), 'dd-MM-yyyy'),
        description: '',
        id: -1,
      },
      ...observations,
    ]);
  };

  const handleCancel = () => {
    const o = [...observations];
    o.shift();
    setObservations(o);
    resetValues();
  };

  const handleChange = (event) => {
    !touched && setTouched(true);
    setDescription(event.target.value);
  };

  const hasError = () => {
    return touched && (!description || description.length > 300);
  };

  const errorMessage = () => {
    if (hasError()) {
      return description.length > 300
        ? 'La descripcion no debe superar los 300 caracteres'
        : 'La descripción es requerida';
    }
    return '';
  };

  return (
    <Dialog
      className={min ? classes.dialog : ''}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <DialogTitle align="center" className={classes.title}>
            <Typography variant="h5" component="p" align="center">
              Observaciones de la lesión
            </Typography>
          </DialogTitle>
          <DialogActions className={classes.addButtonContainer}>
            <Tooltip title="Nueva observación" placement="left">
              <span>
                <Fab
                  color="primary"
                  onClick={handleNewObservation}
                  disabled={newObservation}
                  className={classes.addButton}
                >
                  <Add />
                </Fab>
              </span>
            </Tooltip>
          </DialogActions>
          <DialogContent>
            {observations.length === 0 ? (
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
              >
                Aún no hay observaciones para esta lesión
              </Typography>
            ) : (
              <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="flex-start"
              >
                {observations.slice(page * 3, page * 3 + 3).map((o) => (
                  <Grid
                    item
                    md={3}
                    xs={12}
                    key={o.id}
                    className={min ? classes.cardContainer : ''}
                  >
                    <Card>
                      <Typography color="textSecondary" align="center">
                        {o.date}
                      </Typography>
                      {page === 0 && o === observations[0] && newObservation ? (
                        <form onSubmit={handleSubmit}>
                          <CardContent>
                            <TextField
                              id="standard-multiline-static"
                              label="Descripción"
                              multiline
                              rows={4}
                              value={description}
                              onChange={handleChange}
                              error={hasError()}
                              helperText={errorMessage()}
                              className={classes.descriptionInput}
                            />
                          </CardContent>
                          <CardActions className={classes.actionButtons}>
                            <Tooltip title="Guardar">
                              <span>
                                <IconButton
                                  type="submit"
                                  disabled={
                                    submitting || !description || hasError()
                                  }
                                >
                                  <Done fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="Cancelar">
                              <span>
                                <IconButton
                                  disabled={submitting}
                                  onClick={handleCancel}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </CardActions>
                        </form>
                      ) : (
                        <CardContent>
                          <Typography variant="body2" component="p">
                            {o.description}
                          </Typography>
                        </CardContent>
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <Tooltip title="Atrás" placement="left">
              <span>
                <IconButton
                  onClick={prevPage}
                  disabled={page === 0 || newObservation}
                >
                  <NavigateBefore />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Adelante" placement="right">
              <span>
                <IconButton
                  onClick={nextPage}
                  disabled={page === maxPage || newObservation}
                >
                  <NavigateNext />
                </IconButton>
              </span>
            </Tooltip>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInjuryObservations,
      addInjuryObservation,
      setSuccessMsg,
    },
    dispatch
  );

export default compose(connect(null, mapDispatchToProps))(ObservacionesModal);
