import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  fullScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const LoadingSpinner = () => (
  <div style={styles.fullScreen}>
    <CircularProgress />
  </div>
);

export default LoadingSpinner;
