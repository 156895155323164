import { systemService } from '../../services/system.service'
import { SYSTEM_ACTION_TYPES } from './system.types';

export const fetchSystemTag = () => async dispatch => {
  try {
    const response = await systemService.getSystemTag();
    response
      ? dispatch({
          type: SYSTEM_ACTION_TYPES.FETCH_SYSTEM_TAG_SUCCESS,
          payload: response
        })
      : dispatch({
          type: SYSTEM_ACTION_TYPES.FETCH_SYSTEM_TAG_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
