import Dialog from '@material-ui/core/Dialog/Dialog';
import Button from '@material-ui/core/Button/Button';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50,
  },
  actionsContainer: {
    padding: theme.spacing(2, 3),
  }
}));

const ConfirmationDialog = ({ onClose, onConfirm, message, open, loading, setLoading }) => {
  const classes = useStyles();

  function handleCancel() {
    onClose();
  }

  function handleOk() {
    onConfirm();
  }

  function handleEnter() {
    if (loading) {
      setLoading(false);
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth
      onEnter={handleEnter}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">Confirmar</DialogTitle>
      <DialogContent id="alert-dialog-description" className={classes.container}>
        {!loading && <DialogContentText>{message}</DialogContentText>}
        {loading && <CircularProgress/>}
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={handleCancel} variant="contained" color="primary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleOk} variant="contained" color="primary" disabled={loading}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
