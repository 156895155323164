import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import authReducer from '../store/auth/auth.reducer';
import appReducer from '../store/app/app.reducer';
import anthropometricDataReducer from './anthopometric-data/anthropometricData.reducer';
import teamReducer from './team/team.reducer';
import roleReducer from './role/role.reducer';
import positionReducer from './position/position.reducer';
import categoryReducer from '../store/category/category.reducer';
import genderReducer from '../store/gender/gender.reducer';
import nationalityReducer from '../store/nationality/nationality.reducer';
import sportReducer from '../store/sport/sport.reducer';
import systemReducer from '../store/system/system.reducer';
import statsReducer from './stats/stats.reducer';
import profileReducer from './profile/profile.reducer';

// FALTA REFACTORIZAR
import personReducer from './person/person.reducer';
import notificationReducer from './notification/notification.reducer';
import injuryReducer from './injury/injury.reducer';

export const history = createBrowserHistory();

const mainReducer = combineReducers({
  app: appReducer,
  team: teamReducer,
  role: roleReducer,
  position: positionReducer,
  category: categoryReducer,
  nationality: nationalityReducer,
  person: personReducer,
  auth: authReducer,
  gender: genderReducer,
  sport: sportReducer,
  profile: profileReducer,
  notification: notificationReducer,
  stats: statsReducer,
  system: systemReducer,
  injury: injuryReducer,
  anthropometricData: anthropometricDataReducer,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  // On logout, reset redux state
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined;
  }
  return mainReducer(state, action);
};

export default rootReducer;
