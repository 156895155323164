import { SPORT_ACTION_TYPES } from './sport.types';
import { sportService } from '../../services/sport.service';

export const fetchSports = () => async dispatch => {
  try {
    const response = await sportService.getSports();
    response
      ? dispatch({
          type: SPORT_ACTION_TYPES.FETCH_SPORTS_SUCCESS,
          payload: response
        })
      : dispatch({
          type: SPORT_ACTION_TYPES.FETCH_SPORTS_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
