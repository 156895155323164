// AUTH
export const isLoadingSelector = state => state.auth.isFetching;
export const userSelector = state => state.auth.user;
export const personSelector = state => state.auth.person;
export const isSignedInSelector = state => state.auth.isSignedIn;
export const tokenSelector = state => state.auth.token;
export const authErrorSelector = state => state.auth.error;

// NOTIFICATION
export const notificationsSelector = state => state.notification.notifications;

// ANTHROPOMETRIC DATA
export const getAllAnthropometricData = state => state.anthropometricData.allAnthropometricData;

// TEAM
export const teamsSelector = state => state.team.teams;
export const myTeamsSelector = state => state.team.myTeams;
export const teamDetailSelector = state => state.team.teamDetail;
export const teamPlayersSelector = state => state.team.teamPlayers;
export const teamMembersSelector = state => state.team.teamMembers;
export const positionsByTeamSelector = state => state.team.positionsByTeam;

// ROLE
export const teamRolesSelector = state => state.role.teamRoles;

// POSITION
export const positionsSelector = state => state.position.positions;

// INJURY
export const injuriesSelector = (state) => state.injury.injuries;

// CATEGORY
export const categoriesSelector = state => state.category.categories;
export const categoryErrorSelector = state => state.category.error;

// GENDER
export const gendersSelector = state => state.gender.genders;

// NATIONALITY
export const nationalitiesSelector = state => state.nationality.nationalities;
export const nationalityErrorSelector = state => state.nationality.error;

// SPORTS
export const sportsSelector = state => state.sport.sports;

// SYSTEM
export const systemTagSelector = state => state.system.systemTag;

// PROFILE
export const teamPlayerSelector = (state) => {
    return state.profile.teamPlayer;
};
export const RPEPlayerSelector = (state) => {
return state.profile.RPEPlayer;
};
export const technicalAttributesPlayerSelector = (state) => {
return state.profile.technicalAttributes;
};
export const wellnessPlayerSelector = (state) => {
return state.profile.wellness;
};
export const anthropometricPlayerSelector = (state) => {
return state.profile.anthropometric;
};

// STATS
export const teamRPESelector = state => state.stats.teamRPE;
export const teamRPEWeekSelector = state => state.stats.teamRPEWeek;
export const teamWellnessSelector = state => state.stats.teamWellness;

// PERSON
export const peopleSelector = state => state.person.people;

