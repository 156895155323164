export const TEAM_ACTION_TYPES = {
  FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
  FETCH_TEAMS_ERROR: 'FETCH_TEAMS_ERROR',
  FETCH_MY_TEAMS_SUCCESS: 'FETCH_MY_TEAMS_SUCCESS',
  FETCH_MY_TEAMS_ERROR: 'FETCH_MY_TEAMS_ERROR',
  FETCH_TEAM_SUCCESS: 'FETCH_TEAM_SUCCESS',
  FETCH_TEAM_ERROR: 'FETCH_TEAM_ERROR',
  FETCH_TEAM_PLAYERS_SUCCESS: 'FETCH_TEAM_PLAYERS_SUCCESS',
  FETCH_TEAM_PLAYERS_ERROR: 'FETCH_TEAM_PLAYERS_ERROR',
  FETCH_TEAM_MEMBERS_SUCCESS: 'FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_TEAM_MEMBERS_ERROR: 'FETCH_TEAM_MEMBERS_ERROR',
  FETCH_TEAM_POSITION_SUCCESS: 'FETCH_TEAM_POSITION_SUCCESS',
  FETCH_TEAM_POSITION_BY_TEAM_SUCCESS: 'FETCH_TEAM_POSITION_BY_TEAM_SUCCESS',
  FETCH_TEAM_POSITION_BY_TEAM_ERROR: 'FETCH_TEAM_POSITION_BY_TEAM_ERROR',
  TEAM_ERROR: 'TEAM_ERROR',
  ADD_TEAM_SUCCESS: 'ADD_TEAM_SUCCESS',
  ADD_TEAM_ERROR: 'ADD_TEAM_ERROR',
  UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
  UPDATE_TEAM_ERROR: 'UPDATE_TEAM_ERROR',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_ERROR: 'DELETE_TEAM_ERROR',
  SET_TEAM_VALIDITY_DATE: 'SET_TEAM_VALIDITY_DATE',
};
