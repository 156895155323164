import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem'; 
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import {
  Assignment,
  Warning,
  OfflinePin,
  Edit,
  DeleteSweep,
} from '@material-ui/icons';
import { green, yellow, orange, red } from '@material-ui/core/colors';

import { translations } from '../constants/table-localization';
import moment from 'moment';
import { injuriesSelector } from '../store/selectors';
import * as injuryActions from '../store/injury/injury.actions';
import ConfirmationDialog from '../components/ConfimationDialog';
import ObservacionesModal from '../components/modals/ObservacionesModal';
import { setSuccessMsg } from '../store/app/app.actions';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(3),
    fontFamily: 'Arial',
    '& .MuiTableHead-root': {
      borderTop: '1px solid #e0e0e0',
      borderBottom: '2px solid #e0e0e0',
    },
  },
  categorySelect: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  stateIcon: {
    paddingLeft: '20%',
  },
  actionIcon: {
    paddingLeft: '120px',
    color: 'action',
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Arial',
    width: '130px',
    paddingLeft: '0%',
    color: '#3c9ee5',
  },
  list: {
    paddingLeft: '0%',
  },
}));

translations.header.actions = '';

const InjuryList = ({
  setInjury,
  fetchInjuries,
  injuries,
  deleteInjury,
  setMostrarForm,
  setSuccessMsg,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [injurySelected, setInjurySelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openObservations, setOpenObservations] = useState(false);
  const [observationsInjury, setObservationsInjury] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setLoading(true);
    fetchInjuries()
      .then(() => setLoading(false))
      .catch(() => setLoading(false)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event, injury) => {
    setInjurySelected(injury);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToTop = () => window.scrollTo(0, 0);

  const handleOnEdit = () => {
    setInjury(injurySelected);
    scrollToTop();
  };

  const handleOnDelete = () => {
    if (injurySelected) {
      setLoading(true);
      deleteInjury(injurySelected.id)
        .then(res => {
          setSuccessMsg('Lesion eliminada correctamente')
          setLoading(false);
          handleClose();
          setOpenConfirm(false);
          fetchInjuries();
        })
        .catch(() => {
          setLoading(false);
          handleClose();
          setOpenConfirm(false);
        });
    }
  };

  const confirmDelete = () => {
    setConfirmMessage(() => (
      <span>
        ¿Está seguro que desea <b>eliminar</b> la lesión?
      </span>
    ));
    setOpenConfirm(true);
  };

  const handleOpenObservations = id => {
    setObservationsInjury(id);
    setOpenObservations(true);
  };

  const handleCloseObservations = () => {
    setObservationsInjury(null);
    setOpenObservations(false);
  };

  const tableOptions = {
    search: false,
    sorting: true,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    paging: false,
    addRowPosition: 'first',
    tableLayout: '',
  };

  const columns = [
    {
      title: ' ',
      editable: 'never',
      sorting: false,
      render: rowData => {
        const opt1 = Boolean(rowData.returnToTrainingDate);
        const opt2 = Boolean(rowData.returnToTeam);
        const opt3 = Boolean(rowData.returnToPlayDate);
        switch (true) {
          case opt1 && opt2 && opt3:
            return <OfflinePin style={{ color: green[400] }} />;
          case opt1 && !opt2 && !opt3:
            return <OfflinePin style={{ color: orange[400] }} />;
          case opt1 && opt2 && !opt3:
            return <OfflinePin style={{ color: yellow[400] }} />;
          default:
            return <Warning style={{ color: red[400] }} />;
        }
      },
      cellStyle: {
        paddingLeft: '90px',
      },
    },
    {
      title: 'Estado',
      sorting: false,
      editable: 'never',
      render: rowData => {
        const opt1 = Boolean(rowData.returnToTrainingDate);
        const opt2 = Boolean(rowData.returnToTeam);
        const opt3 = Boolean(rowData.returnToPlayDate);
        switch (true) {
          case opt1 && opt2 && opt3:
            return 'Activo';
          case opt1 && !opt2 && !opt3:
            return 'Rehabilitando solo';
          case opt1 && opt2 && !opt3:
            return 'Rehabilitando en equipo';
          default:
            return 'Inactivo';
        }
      },
    },
    {
      title: 'Nombre',
      field: 'person.firstName',
      editable: 'never',
      render: rowData =>
        `${rowData.person.firstName} ${rowData.person.lastName}`,
    },
    {
      title: 'Plantel',
      field: 'teams',
      editable: 'never',
      render: rowData => (
        <List dense={true}>
          {rowData.teams &&
            rowData.teams.map(team => (
              <ListItem key={team.id} className={classes.list}>
                <ListItemText primary={team.name} />
              </ListItem>
            ))}
        </List>
      ),
    },
    {
      title: 'Tipo de lesion',
      field: 'type',
      render: rowData =>
        rowData.type === 'traumatic' ? 'Traumática' : 'No traumática',
      editComponent: props => {
        return (
          <TextField
            select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            className={classes.categorySelect}
          >
            <MenuItem key="traumatic" value="traumatic">
              Traumática
            </MenuItem>
            <MenuItem key="no_traumatic" value="no_traumatic">
              No traumática
            </MenuItem>
          </TextField>
        );
      },
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Observaciones',
      editable: 'never',
      sorting: false,
      render: rowData => {
        return (
          <Button
            className={classes.button}
            size="large"
            startIcon={<Assignment />}
            onClick={() => handleOpenObservations(rowData.id)}
          >
            Ver detalle
          </Button>
        );
      },
    },
    {
      title: 'Fecha de lesión',
      field: 'date',
      render: rowData => moment(rowData.date).format('DD/MM/YYYY'),
      type: 'date',
      initialEditValue: new Date(),
      headerStyle: {
        fontSize: 'small',
        marginLeft: '0%',
        marginRight: '0%',
      },
    },
  ];

  return (
    <div className={classes.container}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onBlur={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem
            button
            disabled={loading}
            onClick={() => {
              handleOnEdit();
              setMostrarForm(true);
            }}
          >
            <ListItemText primary="Editar" />
            <Edit className={classes.actionIcon} color="action" />
          </ListItem>
          <ListItem button disabled={loading} onClick={confirmDelete}>
            <ListItemText primary="Eliminar" />
            <DeleteSweep className={classes.actionIcon} color="action" />
          </ListItem>
        </List>
      </Popover>
      <ConfirmationDialog
        loading={loading}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
        message={confirmMessage}
        onConfirm={handleOnDelete}
        setLoading={setLoading}
      />
      <ObservacionesModal
        open={openObservations}
        onClose={handleCloseObservations}
        injuryId={observationsInjury}
      />
      <MaterialTable
        className={classes.table}
        title={
          <Typography variant="h6" color="textPrimary" align="center">
            Lesiones
          </Typography>
        }
        onChangeRowsPerPage={value => {
          setPageSize(value);
        }}
        columns={columns}
        options={tableOptions}
        localization={translations}
        isLoading={loading}
        data={injuries}
        actions={[
          {
            icon: 'more_vert',
            tooltip: 'más',
            iconProps: { color: 'action' },
            onClick: (event, rowData) => {
              handleClick(event, rowData);
            },
          },
        ]}
        editable={{
          isEditable: rowData => false,
          isDeletable: rowData => true,
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  injuries: injuriesSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...injuryActions,
      setSuccessMsg,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InjuryList
);
