import Header from '../Header';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../../store/store';
import ViewsContainer from '../ViewsContainer';
import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as authActions from '../../store/auth/auth.actions';
import * as systemActions from '../../store/system/system.actions';
import { isLoadingSelector, isSignedInSelector } from '../../store/selectors';
import LoadingSpinner from '../LoadingSpinner';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ExitIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ListItemText from '@material-ui/core/ListItemText';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { VERSION } from '../../constants/config';
import Button from '@material-ui/core/Button';
import { systemTagSelector } from '../../store/selectors';
import Typography from '@material-ui/core/Typography';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  mainContent: {
    height: 'calc(100% - 56px)',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 64px)',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    height: '100%',
  },
  logoutContainer: {
    width: drawerWidth,
    position: 'fixed',
    bottom: 0,
    padding: '10px 0 10px 0',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const AppWrapper = ({
  auth,
  authRequested,
  isLoading,
  isSignedIn,
  logout,
  fetchSystemTag,
  systemTag,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const goTo = path => {
    history.push(path);
  };

  const doLogout = response => {
    authRequested(true);
    logout().then(() => authRequested(false));
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      auth()
        .then(res => fetchSystemTag())
        .catch(err => console.log(err));
      authRequested(true);
    } else {
      authRequested(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const drawer = (
    <div className={classes.drawerContainer}>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            handleDrawerToggle();
            goTo('/people');
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={'Gestión de personas'} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            handleDrawerToggle();
            goTo('/teams');
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={'Gestión de equipos'} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            handleDrawerToggle();
            goTo('/injuries');
          }}
        >
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary={'Gestión de lesiones'} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            handleDrawerToggle();
            goTo('/anthropometricdata');
          }}
        >
          <ListItemIcon>
            <AssignmentInd />
          </ListItemIcon>
          <ListItemText primary={'Gestión de datos antropométricos'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            handleDrawerToggle();
            goTo('/players');
          }}
        >
          <ListItemIcon>
            <RecentActorsIcon />
          </ListItemIcon>
          <ListItemText primary={'Jugadores'} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            handleDrawerToggle();
            goTo('/squads');
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={'Mis planteles'} />
        </ListItem>
      </List>
      <div className={classes.logoutContainer}>
        {isSignedIn && (
          <Button
            onClick={doLogout}
            className={classes.logoutButton}
            endIcon={<ExitIcon />}
          >
            Salir
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div id="appWrapper">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={classes.root}>
          <Header onDrawerToggled={handleDrawerToggle} />

          {isSignedIn && (
            <nav className={classes.drawer} aria-label="mailbox folders">
              <Hidden mdUp>
                <Drawer
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden smDown>
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
          )}
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.mainContent}>
              <ConnectedRouter history={history}>
                <ViewsContainer />
              </ConnectedRouter>
            </div>
          </main>
        </div>
      )}
      <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
        <Typography style={{ fontSize: '10px' }}>
          {VERSION} - {systemTag}
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  isSignedIn: isSignedInSelector(state),
  systemTag: systemTagSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...authActions, ...systemActions }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AppWrapper
);
