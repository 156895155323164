import { AUTH_ACTION_TYPES } from './auth.types';

export const INITIAL_STATE = {
  isFetching: false,
  token: localStorage.getItem('token') || null,
  user: {},
  person: {},
  roles: [],
  isSignedIn: false,
  currentRole: {},
  permissions: [],
  error: '',
  unauthorized: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.FETCH_USER_START:
      return {
        ...state,
        isFetching: true,
      };
    case AUTH_ACTION_TYPES.FETCH_USER_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        roles: action.payload.roles,
        person: action.payload.person,
        user: action.payload.user,
        isSignedIn: true,
        isFetching: false,
      };
    case AUTH_ACTION_TYPES.FETCH_USER_FAILURE:
      return {
        ...INITIAL_STATE,
      };
    case AUTH_ACTION_TYPES.LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        token: null,
      };
    case AUTH_ACTION_TYPES.USER_UNAUTHORIZED:
      return {
        ...INITIAL_STATE,
        unauthorized: true,
        error: action.msg,
      };
    default:
      return state;
  }
};

export default userReducer;
