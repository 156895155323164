const errors = {
  E100: 'No se puede borrar el genero, ya que esta siendo usado en una persona.',
  E101: 'No se puede borrar la nacionalidad, ya que esta siendo usado en una persona.',
  E102: 'No se puede borrar el tipo de identificación, ya que esta siendo usado en una persona.',
  E103: 'Deporte inválido.',
  E104: 'No se puede borrar la ciudad, ya que esta siendo usada en una persona.',
  E105: 'No se puede borrar la obra social, ya que esta siendo usada en una persona.',
  E106: 'El numero de telefono ingresado es invalido.',
  E107: 'El email se encuentra en uso.',

  E200: 'No se puede borrar la categoría, ya que esta siendo usado en un equipo.',
  E201: 'No se puede borrar el rol, ya que esta siendo usado en un miembro.',
  E202: 'Fecha de inicio del equipo es mayor a la fecha de fin.',
  E203: 'Fecha de inicio del miembro es mayor a la fecha de fin.',
  E204: 'Existen asistencias respondidas que no están contempladas en el nuevo rango de fechas.',
  E205: 'No se puede borrar el deporte, ya que esta siendo usado en un equipo.',
  E206: 'No se puede borrar el deporte, ya que esta siendo usado en una persona.',
  E207: 'No se puede borrar la posición, ya que esta siendo usada en un jugador.',
  // E208: 'El usuario que realiza el request no pertenece al medical_staff, physical_staff, coaching_staff ni es cordinador del team.',
  E209: 'No se poseen los permisos necesarios sobre el equipo para eliminar un miembro.',
  E210: 'La persona ya es miembro del equipo con el rol asignado.',
  E211: 'La persona ya cumple el rol en el equipo que desea actualizar.',
  E212: 'No se puede borrar el miembro, ya que es el único coordinador del equipo.',
  E213: 'No se puede borrar el equipo, ya que tienen miembros asociados.',
  // E214: 'El usuario que realiza el request al momento de la fecha, no tiene permisos sobre el Member porque no pertenece al medical_staff, physical_staff, coaching_staff ni es cordinador del team al que pertenece el miembro.',

  E300: 'No se puede borrar el entrenamiento, ya que esta siendo usado en una fecha de entrenamiento.',
  E301: 'No se puede borrar la descripción de asistencia, ya que esta siendo usada en una asistencia.',
  E303: 'Fecha/Hora de inicio del entrenamiento es mayor a la fecha/hora de fin.',
  E304: 'Fecha de inicio del entrenamiento es mayor a la fecha de finalización del equipo.',

  E400: 'No se puede borrar la evaluación, ya que esta siendo usado en una fecha de evaluación.',
  E401: 'No se puede borrar la evaluación, ya que tiene preguntas asociadas.',
  E402: 'No se puede borrar la fecha de evaluación, ya que tiene respuestas asociadas.',
  E403: 'No se puede borrar la pregunta, ya que tiene rango de respuestas asociadas.',
  E404: 'No se puede borrar la pregunta, ya que tiene respuestas asociadas.',
  E405: 'No se puede borrar el rango de respuesta, ya que tiene respuestas asociadas.',
  E406: 'La evaluación no corresponde a la evaluación RPE.',
  E407: 'La pregunta no existe para dicha evaluación.',
  E408: 'El rango de respuesta no existe para dicha pregunta.',
  E410: 'Una de las preguntas no existe para dicha evaluación.',
  E411: 'Se esperan cinco preguntas para la evaluación Wellness.',
  E412: 'Un rango de respuesta no existe para alguna de las preguntas.',
  E413: 'No se poseen los permisos necesarios sobre el equipo para eliminar o actualizar su fecha de evaluacion.',
  E414: 'No se poseen los permisos necesarios sobre el equipo para asignar una evaluación.',
  E415: 'La evaluación Wellness ya fue respondida.',
  E416: 'La fecha de entrenamiento seleccionada no corresponde al equipo en cuestion.',
  E417: 'No se poseen los permisos necesarios para obtener el RPE del miembro.',
  E418: 'No se poseen los permisos necesarios para obtener el RPE de los miembros del equipo.',
  E419: 'No se poseen los permisos necesarios para responder la evaluacion RPE.',
  E420: 'El miembro consultado no es miembro de ningún equipo como jugador.',
  // E421: 'RPE para un miembro: Parámetro offsetCl mayor a parámetro days.',
  // E422: 'RPE para un miembro: Parámetro cantDaysAl mayor a parámetro days.',
  // E423: 'RPE para un miembro: Parámetro cantDaysMonotony mayor a parámetro days.',
  // E424: 'RPE para un equipo: Parámetro offsetCl mayor a parámetro days.',
  // E425: 'RPE para un equipo:Parámetro cantDaysAl mayor a parámetro days.',
  // E426: 'RPE para un equipo: Parámetro cantDaysMonotony mayor a parámetro days.',
  E427: 'No se poseen los permisos necesarios para obtener el Wellness del miembro.',
  E428: 'No se poseen los permisos necesarios para obtener el Wellness de los miembros del equipo.',
  E448:  "El equipo en cuestion no se encuentra vigente",
  E508: 'No se poseen los permisos necesarios sobre la persona',
};

export default errors;
