import { GENDER_ACTION_TYPES } from './gender.types';
import { genderService } from '../../services/gender.service';

export const fetchGenders = () => async dispatch => {
  try {
    const response = await genderService.getGenders();
    response
      ? dispatch({
          type: GENDER_ACTION_TYPES.FETCH_GENDERS_SUCCESS,
          payload: response
        })
      : dispatch({
          type: GENDER_ACTION_TYPES.FETCH_GENDERS_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
