import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: "0px 3px 3px -2px rgba(0 0 0 / 0.20), 0px 3px 4px 0px rgba(0 0 0 / 0.14), 0px 1px 8px 0px rgba(0 0 0 / 0.12)",
  },
  container: {
    maxHeight: 493,
    marginTop: 9,
  },
  header: {
    backgroundColor: '#1F78B4',
    color: 'white',
    padding: 10,
  },
  body: {
    backgroundColor: '#1992DF',
  },
  column: {
    color: 'white',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  columnName: {
    fontSize: 14,
    fontWeight: 500,
  },
  columnPosition: {
    fontSize: 10,
    fontWeight: 100,
    fontStyle: 'italic',
    display: 'block',
  },
  pagination: {
    color: 'white',
    backgroundColor: '#1F78B4',
  },
});

const NameColumn = ({ name, role }) => {
  const classes = useStyles();
  return (
    <p className={classes.columnName}>
      {name}
      <span className={classes.columnPosition}>{role}</span>
    </p>
  );
};

const LastNameColumn = ({ name }) => {
  const classes = useStyles();
  return (
    <p className={classes.columnName} style={{ marginBottom: '35px' }}>
      {name}
    </p>
  );
};

const COLOR = {
  3: 'red',
  2: 'yellow',
  1: 'green',
  0: 'lightgray',
};

const StatusColumn = ({ status }) => {
  return <div style={{ backgroundColor: COLOR[status], width: 15, height: 15, borderRadius: '50%' }}/>;
};

const COMMON_COLUMNS = [
  { id: 'number', align: 'left', label: 'N°', minWidth: 10 },
  { id: 'firstName', align: 'left', label: 'Nombre', minWidth: 60 },
  {
    id: 'lastName',
    label: 'Apellido',
    minWidth: 60,
    align: 'left',
  },
  { id: 'status', align: 'left', label: 'Estado', minWidth: 10 },
];

const StickyHeadTable = ({ allMembersWellness, goToProfile }) => {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [columns, setColumns] = useState(COMMON_COLUMNS);

  useEffect(() => {
    if (allMembersWellness && allMembersWellness.length > 0) {
      const item = allMembersWellness.find(i => i.data.length > 0);
      if (item) {
        setColumns(COMMON_COLUMNS.concat(item.data.map(item => (
          {
            id: item.clave,
            key: item.clave,
            label: item.clave,
            minWidth: 5,
            align: 'left',
          }
        ))));
      }
    }
  }, [allMembersWellness]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  className={classes.header}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {allMembersWellness
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(item => (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.member.id}>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.column}
                      onClick={() =>
                        goToProfile(
                          item.member.id,
                          item.member.person.id,
                        )
                      }
                    >
                      {column.id === 'firstName' ? (
                        <NameColumn
                          name={item.member.person.firstName}
                          role={item.member.roleTeam.description}
                        />
                      ) : column.id === 'number' ? (
                        item.member.player && item.member.player.number
                      ) : column.id === 'lastName' && item.member.person ? (
                        <LastNameColumn name={item.member.person.lastName}/>
                      ) : column.id === 'status' ? (
                        <StatusColumn status={item.status}/>
                      ) : item.data && item.data.find(res => res.clave === column.id) ?
                        item.data.find(res => res.clave === column.id).valor
                        : '-'
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[10, 25, 100]}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Filas por página"
        component="div"
        count={allMembersWellness.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default StickyHeadTable;
