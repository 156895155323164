import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';
import queryString from 'query-string';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import logo from '../assets/logo.png';
import { LOGIN_BASE_URL, GOOGLE_CLIENT_ID } from '../constants/config';
import defaultInstance from '../utils/defaultInstance';
import {
  isSignedInSelector,
  personSelector,
  userSelector,
  isLoadingSelector,
  authErrorSelector,
} from '../store/selectors';
import * as notificationActions from '../store/notification/notification.actions';
import { authRequested, loginWithGoogle } from '../store/auth/auth.actions';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paper: {
    maxWidth: '400px',
    minWidth: '300px',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: '30px',
  },
  errorMessage: {
    marginTop: '5px',
  },
  loginGoogle: {
    marginBottom: 20,
    color: 'black',
  },
  appleLogin: {
    height: 42,
    boxShadow:
      'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;',
    border: '1px solid transparent;',
    marginBottom: 20,
    cursor: 'pointer',
  },
}));

const Login = ({
  authRequested,
  authError,
  isLoading,
  loginWithGoogle,
  loginSuccess,
  isSignedIn,
  user,
  person,
  history,
  location: { search },
  enqueueSnackbar,
  location,
  setAuthError,
}) => {
  const classes = useStyles();

  const APPLE_REDIRECT = `${LOGIN_BASE_URL}/login/apple/?backend=${defaultInstance()}`;

  const parseJwt = data => {
    try {
      return JSON.parse(atob(data.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (location) {
      const data = queryString.parse(location.search).data;
      if (data) {
        const decoded = parseJwt(data);
        if (decoded && decoded.key) {
          localStorage.setItem('token', decoded.key);
          loginSuccess(decoded);
        } else {
          setAuthError('El usuario no tiene acceso al sistema');
        }
        history.replace('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * On Google Sign in failure
   */
  const onFailure = () => {
    authRequested(false);
    enqueueSnackbar(
      'Ha ocurrido un error, por favor intente nuevamente',
      'error'
    );
  };

  /**
   * On Google Sign in success
   */
  const onSuccess = response => {
    if (response.accessToken && response.tokenId) {
      loginWithGoogle(response.accessToken, response.tokenId).then(res => {
        authRequested(false);
        if (res) history.push('/');
      });
    } else {
      onFailure();
    }
  };

  const onRequest = () => {
    authRequested(true);
  };

  useEffect(() => {
    if (authError) {
      enqueueSnackbar(authError, 'error');
      setAuthError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError]);

  useEffect(() => {
    if (isSignedIn) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} elevation={3}>
        <Typography className={classes.title} variant="h4">
          Machi
        </Typography>
        <img width="300px" src={logo} alt="logo" />
        <GoogleLogin
          disabled={isLoading}
          buttonText="Iniciar Sesión con Google"
          clientId={GOOGLE_CLIENT_ID}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          // autoLoad="true" para que abra automaticamente
          onRequest={onRequest}
          className={classes.loginGoogle}
        />
        <div className={classes.appleLogin}>
          <AppleLogin
            clientId="linti.deportes.machi.service"
            redirectURI={APPLE_REDIRECT}
            scope="name email"
            responseMode="form_post"
            designProp={{
              locale: 'es_ES',
              color: 'white',
              border_radius: 2,
              scale: 1,
              height: 42,
              width: 218,
            }}
          />
        </div>
        <Typography
          className={classes.errorMessage}
          variant={'body1'}
          color={'error'}
        >
          {authError}
        </Typography>
      </Paper>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  isSignedIn: isSignedInSelector(state),
  user: userSelector(state),
  person: personSelector(state),
  authError: authErrorSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authRequested,
      loginWithGoogle,
      ...notificationActions,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);
