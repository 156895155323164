/**
 *
 * @param message Message to display
 * @param variant Variant 'success', 'warning', 'error'
 * @returns {{notification: {options: {variant: *}, message: *, key: number}, type: string}}
 */
export const enqueueSnackbar = (message, variant) => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    message,
    key: new Date().getTime() + Math.random(),
    options: { variant },
  },
});

export const removeSnackbar = key => ({
  type: 'REMOVE_SNACKBAR',
  key,
});
