import { injuryService } from '../../services/injury.service';
import { INJURY_ACTION_TYPES } from './injury.types';

export const fetchInjuries = () => async (dispatch) => {
  try {
    const response = await injuryService.getInjuries()
    response
      ? dispatch({
          type: INJURY_ACTION_TYPES.FETCH_INJURIES_SUCCESS,
          payload: response,
        })
      : dispatch({
          type: INJURY_ACTION_TYPES.FETCH_INJURIES_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addInjury = (injury) => async (dispatch) =>{
  try {
    const response = await injuryService.addInjury(injury)
    response
      ? dispatch({
          type: INJURY_ACTION_TYPES.ADD_INJURY_SUCCESS,
        })
      : dispatch({
          type: INJURY_ACTION_TYPES.ADD_INJURY_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const updateInjury = (injury, id) => async (dispatch) => {
  try {
    const response = await injuryService.updateInjury(injury, id)
    response
      ? dispatch({
          type: INJURY_ACTION_TYPES.UPDATE_INJURY_SUCCESS,
        })
      : dispatch({
          type: INJURY_ACTION_TYPES.UPDATE_INJURY_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteInjury = (id) => async (dispatch) => {
  try {
    const response = await injuryService.deleteInjury(id)
    response
      ? dispatch({
          type: INJURY_ACTION_TYPES.DELETE_INJURY_SUCCESS,
        })
      : dispatch({
          type: INJURY_ACTION_TYPES.DELETE_INJURY_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

//OBSERVATIONS

export const fetchInjuryObservations = (id) => async (dispatch) => {
  try {
    const response = await injuryService.getInjuryObservations(id)
    response
      ? dispatch({
          type: INJURY_ACTION_TYPES.FETCH_INJURY_OBSERVATIONS_SUCCESS,
          payload: response,
        })
      : dispatch({
          type: INJURY_ACTION_TYPES.FETCH_INJURY_OBSERVATIONS_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addInjuryObservation = (observation) => async (dispatch) => {
  try {
    const response = await injuryService.saveInjuryObservation(observation)
    response
      ? dispatch({
          type: INJURY_ACTION_TYPES.ADD_INJURY_OBSERVATION_SUCCESS,
          payload: response,
        })
      : dispatch({
          type: INJURY_ACTION_TYPES.ADD_INJURY_OBSERVATION_ERROR,
        });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};



