import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const SelectMultiple = ({ value, onChange, options }) => {
  const getSelectInitialState = () =>
    options.filter(option => {
      return !!value.find(initialOption => initialOption.id === option.id);
    });

  const initialSelectState = getSelectInitialState();
  const [selectedOptions, setSelectedOptions] = useState(initialSelectState);

  const onSelected = e => {
    setSelectedOptions(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Select
      multiple
      value={selectedOptions}
      onChange={onSelected}
      input={<Input />}
      MenuProps={{
        PaperProps: {
          style: {
            width: 250,
          },
        },
      }}
      style={{ width: '100%' }}
      renderValue={selected => selected.map(item => item.name).join(', ')}
    >
      {options.map(c => (
        <MenuItem key={`item-${c.id}`} value={c}>
          <Checkbox
            checked={selectedOptions.map(o => o.id).indexOf(c.id) > -1}
          />
          <ListItemText primary={c.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectMultiple;
