import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  })
);
const CustomAvatar = ({ image, size }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar
        alt="Profile Image"
        src={image}
        className={size !== 'small' && classes.large}
      />
    </div>
  );
};

CustomAvatar.defaultProps = {
  image: '',
  size: 'small',
};

CustomAvatar.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string,
};
export default CustomAvatar;
