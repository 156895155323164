import { runFetch, runFetchFile } from './fetchHandler';
import { requestErrorHandler } from './requestErrorHandler';

export const requests = {
  get: async (url, params) => {
    try {
      if (params) {
        url += `?${new URLSearchParams(params).toString()}`;
      }
      const response = await runFetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if(response) {  
        return Promise.resolve(response)
        }
      else{
        return Promise.reject()
      }
    } catch (error) {
      return requestErrorHandler(error);
    }
  },
  post: async (url, params) => {
    try {
      const response = await runFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      if(response) {  
        return Promise.resolve(response)
      }
      else{
        return Promise.reject()
      }
    } catch (error) {
      return requestErrorHandler(error);
    }
  },
  put: async (url, params) => {
    try {
      const response = await runFetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      if(response) {  
        return Promise.resolve(response)
        }
      else{
        return Promise.reject()
      }
    } catch (error) {
      return requestErrorHandler(error);
    }
  },
  delete: async (url, params) => {
    try {
      const response = await runFetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      if(response) {  
        return Promise.resolve(response)
        }
      else{
        return Promise.reject()
      }
    } catch (error) {
      return requestErrorHandler(error);
    }
  },
  postFile: async (url, params) => {
    try {
      const response = await runFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: params,
      });
      if(response) {  
        return Promise.resolve(response)
        }
      else{
        return Promise.reject()
      }
    } catch (error) {
      return requestErrorHandler(error);
    }
  },
  putFile: async (url, params) => {
    try {
      const response = await runFetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body: params,
      });
      if(response) {  
        return Promise.resolve(response)
        }
      else{
        return Promise.reject()
      }
    } catch (error) {
      return requestErrorHandler(error);
    }
  },
  getFile: async (url, params) => {
    try {
      if (params) {
        url += `?${new URLSearchParams(params).toString()}`;
      }
      const response = await runFetchFile(url, { method: 'GET' });
      if(response) {  
        return Promise.resolve(response)
        }
      else{
        return Promise.reject()
      }
    } catch (error) {
      return requestErrorHandler(error);
    }
  },
};
