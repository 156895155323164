import { BASE_URL } from '../config';
import defaultImage from '../../utils/defaultImage';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { format } from 'date-fns';

const useStyles = makeStyles();

export const defaultColumnsConstructor = (positions) => {
    return([{
        field: 'image',
        title: '',
        editable: 'never',
        render: rowData => {
          return (
            <img
            alt='#'
              src={
                rowData.person && rowData.person.image
                  ? BASE_URL + rowData.person.image
                  : defaultImage()
              }
              style={{ width: 50, borderRadius: '50%' }}
            />
          );
        },
      },
      {
        title: 'Nombre',
        field: 'person.firstName',
        cellStyle: {
          width: '250px',
        },
        editable: 'never',
      },
      {
        title: 'Apellido',
        field: 'person.lastName',
        cellStyle: {
          width: '250px',
        },
        editable: 'never',
      },
      {
        title: 'Rol',
        field: 'roleTeam.description',
        cellStyle: {
          width: '250px',
        },
        editable: 'never',
      },
      {
        title: 'Posición',
        field: 'player.position.id',
        render: rowData =>
          rowData.player && rowData.player.position
            ? rowData.player.position.description
            : '-',
        cellStyle: {
          width: '250px',
        },
        editable: (column, rowData) => !!rowData.player,
        editComponent: props => {
          return (
            <TextField
              select
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              error={!props.value}
              fullWidth
              className={useStyles.select}
            >
              {positions.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {c.description}
                </MenuItem>
              ))}
            </TextField>
          );
        },
      },
      {
        title: 'Número',
        field: 'player.number',
        emptyValue: '-',
        editable: (column, rowData) => !!rowData.player,
        cellStyle: {
          width: '250px',
        },
      },
      {
        title: 'Fecha fin',
        field: 'endDate',
        render: rowData =>
          format(new Date(moment(rowData.endDate).toISOString()), 'dd-MM-yyyy'),
        type: 'date',
        cellStyle: {
          width: '200px',
        },
      },
    ])
}