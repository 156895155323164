export const ENVIRONMENT = window._env_
  ? window._env_.REACT_APP_ENVIRONMENT === 'TESTING'
    ? window._env_.REACT_APP_ENVIRONMENT
    : 'PRODUCTION'
  : 'TESTING';

export const API_BASE_URL =
  window._env_ && window._env_.REACT_APP_API_BASE_URL
    ? `${window._env_.REACT_APP_API_BASE_URL}/v2`
    : 'https://machi-dev-api.linti.unlp.edu.ar/api/v2';

//TODO: AGREGAR VARIABLE DE ENTORNO
export const LOGIN_BASE_URL = 'https://machi-gatekeeper.linti.unlp.edu.ar';

// 'prod' 'gelp' 'dev'
export const INSTANCE =
  window._env_ && window._env_.REACT_APP_INSTANCE
    ? window._env_.REACT_APP_INSTANCE
    : 'dev';

export const BASE_URL = API_BASE_URL.split('/api')[0];

export const VERSION =
  window._env_ && window._env_.REACT_APP_VERSION
    ? window._env_.REACT_APP_VERSION
    : '1';

export const GOOGLE_CLIENT_ID =
  window._env_ && window._env_.REACT_APP_GOOGLE_CLIENT_ID
    ? window._env_.REACT_APP_GOOGLE_CLIENT_ID
    : '605163180817-hl3jrr15n3iegbf1vd2ub01u57oau00q.apps.googleusercontent.com';
