import { INSTANCE } from '../constants/config';

/**
 * En base a la instancia actual retorna el path de la imagen por defecto a mostrar
 * @returns {string}
 */
export default function defaultImage() {
  switch (INSTANCE) {
    case 'prod':
      return '/img/profile/linti_logo.jpg';
    case 'gelp':
      return '/img/profile/escudo.png';
    default:
      return '/img/profile/linti_logo.jpg';
  }
}
