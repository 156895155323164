import { roleService } from '../../services/role.service';
import { ROLE_ACTION_TYPES } from './role.types';

export const fetchTeamRoles = () => async dispatch => {
    try {
        const response = await roleService.getTeamRoles();
        response
          ? dispatch({
              type: ROLE_ACTION_TYPES.FETCH_TEAM_ROLES_SUCCESS,
              payload: response
            })
          : dispatch({
              type: ROLE_ACTION_TYPES.FETCH_TEAM_ROLES_ERROR,
            });
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
  };