import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    categorySelect: {
      width: '100%',
    },
    rootField: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  }));

export const defaultColumnsConstructor = (categories, sports) => {
    return([{
            title: 'Nombre',
            field: 'name',
            editComponent: props => (
                <FormControl fullWidth>
                <TextField
                    className={useStyles.rootField}
                    placeholder="Nombre *"
                    type="text"
                    size="small"
                    margin="dense"
                    required
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    error={!props.value}
                />
                </FormControl>
            ),
            cellStyle: {
                width: '250px',
            },
            },
            {
            title: 'Descripción',
            field: 'description',
            editComponent: props => (
                <FormControl fullWidth>
                <TextField
                    placeholder="Descripción *"
                    type="text"
                    size="small"
                    margin="dense"
                    required
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    error={!props.value}
                />
                </FormControl>
            ),
            cellStyle: {
                width: '300px',
            },
            },
            {
            title: 'Categoría',
            field: 'category.id',
            render: rowData => rowData.category.description,
            editComponent: props => {
                return (
                <TextField
                    select
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    error={!props.value}
                    fullWidth
                    className={useStyles.categorySelect}
                >
                    {categories.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                        {c.description}
                    </MenuItem>
                    ))}
                </TextField>
                );
            },
            cellStyle: {
                width: '200px',
            },
            },
            {
            title: 'Deporte',
            field: 'sport.id',
            render: rowData => rowData.sport.name,
            editComponent: props => {
                return (
                <TextField
                    select
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    error={!props.value}
                    fullWidth
                    className={useStyles.categorySelect}
                >
                    {sports.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                        {c.name}
                    </MenuItem>
                    ))}
                </TextField>
                );
            },
            cellStyle: {
                width: '150px',
            },
            },
            {
            title: 'Fecha inicio',
            field: 'initialDate',
            render: rowData => moment(rowData.initialDate).format('DD-MM-YYYY'),
            type: 'date',
            initialEditValue: new Date(),
            cellStyle: {
                width: '150px',
            },
            },
            {
            title: 'Fecha fin',
            field: 'endDate',
            render: rowData => moment(rowData.endDate).format('DD-MM-YYYY'),
            type: 'date',
            initialEditValue: new Date(),
            cellStyle: {
                width: '150px',
            },
            },
    ])
}