import { ANTHROPOMETRIC_DATA_ACTION_TYPES } from './anthropometricData.types';

export const INITIAL_STATE = {
  allAnthropometricData: [],
  error: '',
};

const anthropometricDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANTHROPOMETRIC_DATA_ACTION_TYPES.SET_ANTHROPOMETRIC_DATA:
      return {
        ...state,
        allAnthropometricData: action.payload,
      };
    default:
      return state;
  }
};

export default anthropometricDataReducer;
